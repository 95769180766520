import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Nora } from 'primeng/themes/nora';
import { definePreset } from 'primeng/themes';
import { PreloaderService } from './core/services/preloader.service';
import { LocalStorageService } from './core/services/localStorage.service';
import { CoordinateService } from './core/services/coordinates/coordinate.service';
import { TokenService } from './core/services/api/user/token.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent {
	constructor(
		public preloaderService: PreloaderService,
		private coordinateService: CoordinateService,
		private config: PrimeNGConfig,
		private tokenService: TokenService,
		private localStorageService: LocalStorageService,
	) {
		coordinateService.init();
		const myPreset = definePreset(Nora, {
			semantic: {
				primary: {
					50: '#3e7efd',
					100: '#3e7efd',
					200: '#3e7efd',
					300: '#3e7efd',
					400: '#3e7efd',
					500: '#3e7efd',
					600: '#3e7efd',
					700: '#3e7efd',
					800: '#3e7efd',
					900: '#3e7efd',
					950: '#3e7efd',
				},
			},
		});
		this.config.theme.set({
			preset: myPreset,
			options: {
				prefix: 'p',
				darkModeSelector: '',
				cssLayer: false,
			},
		});
	}
}
