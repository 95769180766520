import { CommonModule } from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ButtonModule } from 'primeng/button';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlatformModule } from '@angular/cdk/platform';
import { HttpInterceptorService } from './core/services/interceprot.service';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DirectiveModule } from './shared/directives/directive.module';
import { ModalModule } from './shared/modal/modal.module';
import { CoordinateService } from './core/services/coordinates/coordinate.service';
import { NavBarComponent } from "./shared/components/mobile/nav-bar/nav-bar";
import { AppInitializerService } from './app-initializer.service';

export function initCoodinate(coordinateService: CoordinateService) {
	return () => coordinateService.init();
}

export function initializeApp(appInitializerService: AppInitializerService) {
	return (): Promise<void> => appInitializerService.run();
}
@NgModule({
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		CommonModule,
		ScrollingModule,
		DirectiveModule,
		SharedModule,
		ModalModule,
		ButtonModule,
		NavBarComponent,
		PlatformModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptorService,
			multi: true,
		},
		{
			provide: new InjectionToken('INIT_COORDINATE'),
			useFactory: CoordinateService,
			multi: true,
		},
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimationsAsync(),
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [AppInitializerService],
			multi: true
		}
	],
})
export class AppModule {}
