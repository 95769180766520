import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from 'src/app/core/interfaces/enums';
import { LocalStorageService } from './localStorage.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
	constructor(
		private localStorageService: LocalStorageService,
		private router: Router
	) { }

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const authRequest = this.handleAuthentication(req);

		return next.handle(authRequest).pipe(catchError((error: any) => {
			if (error instanceof HttpErrorResponse) {
				if (error.status === 401) {
					// Перенаправление на auth страницу в случае ошибки 401
					localStorage.clear();
					this.router.navigateByUrl('auth');
				} else {
					// Сохраняем существующую логику обработки ошибок
					if (!error.ok) {
						console.error('Запрос не прошел');
					}
				}
			}
			return throwError(error);
		}));
	}

	private handleAuthentication(request: HttpRequest<any>): HttpRequest<any> {
		const token = this.localStorageService.getUserToken();
		if (token) {
			return request.clone({
				headers: request.headers.set('Authorization', token).set('Client', 'browser'),
			});
		}

		if (request.url.includes('guest/token') || request.url.includes('signin')) {
			return request.clone({
				headers: request.headers.set('Client', 'browser')
			});
		}

		const guestToken = this.localStorageService.getItemLocalStorage<string>(User.GUEST_DATA);
		return request.clone({
			headers: request.headers.set('Authorization', guestToken!).set('Client', 'browser'),
		});

	}
}
